@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: "Inter";
	src: local("Inter"),
		url("../public/fonts/Inter-Italic-VariableFont_opsz\,wght.woff2")
			format("woff2"),
		url("../public/fonts/Inter-Italic-VariableFont_opsz\,wght.ttf")
			format("truetype");
	font-weight: 100 900;
	font-style: italic;
}

@font-face {
	font-family: "Inter";
	src: local("Inter"),
		url("../public/fonts/Inter-VariableFont_opsz\,wght.woff2") format("woff2"),
		url("../public/fonts/Inter-VariableFont_opsz\,wght.ttf") format("truetype");
	font-weight: 100 900;
	font-style: normal;
}

html,
body {
	max-width: 100vw;
	overflow-x: hidden;
}

body {
	color: var(--foreground);
	background: var(--background);
	font-family: Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
		Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
	letter-spacing: 0.0125em;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

a {
	color: rgb(192, 90, 240);
	box-sizing: border-box;
	text-decoration: underline;
  
	/* background: linear-gradient(to right, rgb(192, 90, 240), rgb(192, 90, 240)),
		linear-gradient(to left, transparent, transparent); */
	/* background-size: 100% 0.1rem, 0 0.1rem;
	background-position: 100% 100%, 0 100%; */
	background-repeat: no-repeat;
	-webkit-box-decoration-break: slice;
	box-decoration-break: slice;
}
/* 
a:hover {
	animation: linkUnderlineAnim var(--link-length) ease;
}

@keyframes linkUnderlineAnim {
	0% {
		background-size: 100% 0.1rem, 0 0.1rem;
		background-position: 100% 100%, 0 100%;
	}
	50% {
		background-size: 0% 0.1rem, 100% 0.1rem;
		background-position: 100% 100%, 0 100%;
	}

	50.0001% {
		background-size: 0% 0.1rem, 100% 0.1rem;
		background-position: 0% 100%, 100% 100%;
	}
	100% {
		background-size: 100% 0.1rem, 0 0.1rem;
		background-position: 0% 100%, 100% 100%;
	}
} */

input[type="date"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
select,
textarea {
	width: 100%;
	border: 1px solid #666;
	border-radius: 0;
	padding: 0.5rem 1rem;
	box-sizing: border-box;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 17px;
	font-weight: 400;
	letter-spacing: 0.012em;
	font-style: normal;
}

input {
appearance: auto;
color: canvastext;
background-color: canvas;
border-top-width: 2px;
border-right-width: 2px;
border-bottom-width: 2px;
border-left-width: 2px;
border-top-style: inset;
border-right-style: inset;
border-bottom-style: inset;
border-left-style: inset;
border-top-color: gray;
border-right-color: gray;
border-bottom-color: gray;
border-left-color: gray;
padding-top: 1px;
-webkit-rtl-ordering: logical;
-webkit-user-select: text;
cursor: auto;
}

input, textarea, select, button {
margin-top: 0em;
color: initial;
letter-spacing: normal;
word-spacing: normal;
line-height: normal;
text-transform: none;
text-indent: 0px;
text-shadow: none;
display: inline-block;
text-align: start;
}